import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";

// Gets the default namespace that we show the user when they log in.
// This corresponds to the first namespace of the first org that they have access to.
export async function getDefaultNamespace(): Promise<Api.ListNamespaces_Item> {
  const listOrgsRequest: Api.ListOrgs_Request = {};
  const listOrgsResponse: Response = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, /* options */ {});
  await ApiUtils.assertStatus(listOrgsResponse, 200);
  const { orgs }: Api.ListOrgs_Response = await listOrgsResponse.json();

  const orgId: string = orgs[0].orgId;
  const listNamespacesRequest: Api.ListNamespaces_Request = { orgId };
  const listNamespacesResponse: Response = await ApiUtils.post(
    "/api/ListNamespaces",
    listNamespacesRequest,
    /* options */ {
      subtraceTags: { org_id: orgId },
    },
  );
  await ApiUtils.assertStatus(listNamespacesResponse, 200);

  const { namespaces }: Api.ListNamespaces_Response = await listNamespacesResponse.json();
  ArrayUtils.assertLengthAtLeast(namespaces, 1);
  return namespaces[0];
}
