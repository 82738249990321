export function assertLengthAtLeast<T>(array: T[], minLength: number): void {
  if (array.length < minLength) {
    throw new Error(
      `Expected array length to be at least ${minLength}, got an array of length ${array.length} instead`,
    );
  }
}

export function extendByRepetition<T>(array: T[], finalLength: number): T[] {
  const result: T[] = [];

  for (let i: number = 0; i < finalLength; i++) {
    result.push(array[i % array.length]);
  }

  return result;
}

export function findIndexOrThrow<T>(array: T[], predicate: (item: T) => boolean): number {
  const index: number = array.findIndex(predicate);
  if (index === -1) {
    throw new Error("Element not found in array");
  }
  return index;
}
