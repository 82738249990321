import React from "react";
import * as StyleUtils from "Utils/StyleUtils";
import { Spinner } from "./Spinner";

export function Button(props: ButtonProps): React.ReactElement {
  return (
    <button
      aria-label={props.ariaLabel ?? props.label}
      className={StyleUtils.mergeClassNames(
        "px-4 outline outline-2 outline-sky-900/60 text-sm text-white font-medium rounded-md bg-sky-700/95 hover:bg-sky-700 group",
        "disabled:bg-slate-600 disabled:outline-none",
        props.className,
      )}
      disabled={props.disabled}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
    >
      <span className="flex justify-center">
        {props.showSpinner ? <Spinner className="mr-[2px]" /> : null}
        {props.icon}
        {props.label}
        {props.showArrow ? (
          <span
            className={StyleUtils.mergeClassNames("inline-block ml-2", {
              "transition ease-in-out group-hover:translate-x-[2px]": !props.disabled,
            })}
          >
            &rarr;
          </span>
        ) : null}
      </span>
    </button>
  );
}

export interface ButtonProps {
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  label: string;
  showArrow?: boolean;
  showSpinner?: boolean;

  onClick: () => void;
  onMouseLeave?: () => void;
}
