import * as React from "react";
import { User, Loader2 } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";

function Row(props: { user: Api.ListUsers_Item }): React.ReactNode {
  const joinTime = new Date(props.user.joinTime);

  return (
    <div className="w-full py-2 items-center grid grid-cols-12 hover:bg-zinc-900/25 group">
      <div className="px-2 col-span-8 text-zinc-300 text-xs truncated text-ellipsis flex items-center space-x-2">
        <span className="w-[14px] h-[14px] flex items-center">
          <User />
        </span>
        <span>{props.user.email}</span>
      </div>
      <div title={joinTime.toISOString()} className="px-2 col-span-3 text-zinc-400 text-xs">
        {joinTime.toDateString()}
      </div>
    </div>
  );
}

function List(props: { users: Api.ListUsers_Item[] }): React.ReactNode {
  return (
    <div className="w-full z-0">
      <div className="flex mb-4">
        <span className="px-3 py-1 bg-zinc-800/80 rounded-full text-zinc-500 text-[10px] font-semibold">
          {props.users.length} {props.users.length === 1 ? "user" : "users"}
        </span>
      </div>
      <div className="w-full flex flex-col divide-y divide-zinc-900">
        <div className="rounded w-full py-2 items-center grid grid-cols-12">
          <div className="px-2 col-span-8 text-zinc-500/80 text-[10px] uppercase font-semibold">Email</div>
          <div className="px-2 col-span-3 text-zinc-500/80 text-[10px] uppercase font-semibold">Joined</div>
        </div>
        {props.users.map((user) => (
          <Row key={user.userId} user={user} />
        ))}
      </div>
    </div>
  );
}

export function UsersPage(): React.ReactNode {
  const [users, setUsers] = React.useState<Api.ListUsers_Item[] | undefined>(undefined);
  const [namespace] = CustomHooks.useNamespaceState();
  const { namespaceId } = namespace;

  React.useEffect(() => {
    const controller: AbortController = new AbortController();

    async function refreshUsers(signal?: AbortSignal): Promise<void> {
      const request: Api.ListUsers_Request = { namespaceId };
      try {
        const response: Response = await ApiUtils.post("/api/ListUsers", request, { signal, subtraceTags: { namespace_id: namespaceId } });
        await ApiUtils.assertStatus(response, 200);
        const { users }: Api.ListUsers_Response = await response.json();
        setUsers(users);
      } catch {
        if (signal?.aborted) {
          // Do nothing, the API call was canceled
        }
      }
    }

    refreshUsers(controller.signal);

    return (): void => {
      controller.abort("Cleaning up effect refreshUsers");
    };
  }, [namespaceId]);

  if (users == null) {
    return (
      <div className="pt-4 w-full flex items-center">
        <div className="w-full h-full flex justify-center items-center rounded-md text-zinc-600">
          <div className="w-4 h-4 flex justify-center items-center">
            <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
              <Loader2 />
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-xl px-8 py-12">
        <div className="flex flex-col space-y-8">
          <span className="text-zinc-200 text-sm">Namespace admins</span>
          <List users={users.filter((user) => user.hasWrite)} />
          <span className="text-zinc-200 text-sm">Namespace members</span>
          <List users={users.filter((user) => !user.hasWrite)} />
        </div>
      </div>
    </div>
  );
}
