import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowDownUp, Grip, Key, Users, BookOpen, MessagesSquare, LogOut, Loader2, ChevronLeftIcon, ChevronRightIcon } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as StrictUtils from "Utils/StrictUtils";
import { Select } from "DesignComponents/Select";

const Navlink = (props: { path: string; icon: React.ReactElement; text: string }): React.ReactNode => {
  const location = ReactRouterDom.useLocation();
  return (
    <Link
      to={props.path}
      className={[
        "flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100",
        location.pathname === props.path ? "bg-zinc-700/80 hover:bg-zinc-700" : "bg-transparent hover:bg-zinc-800",
      ].join(" ")}
    >
      <div className="w-4 h-4 flex justify-center items-center">
        <span className="w-[14px] h-[14px] flex items-center">{props.icon}</span>
      </div>
      <span>{props.text}</span>
    </Link>
  );
};

function PageSelector(): React.ReactNode {
  return (
    <div className="flex flex-col space-y-1">
      <Navlink path="/dashboard/requests" icon={<ArrowDownUp />} text="Requests" />
      <Navlink path="/dashboard/tokens" icon={<Key />} text="Tokens" />
      <Navlink path="/dashboard/users" icon={<Users />} text="Users" />
      <Navlink path="/dashboard/namespaces" icon={<Grip />} text="Namespaces" />
    </div>
  );
}

function HelpLinks(): React.ReactNode {
  const Discord = (): React.ReactNode => (
    <svg xmlns="http://www.w3.org/2000/svg" width={127.14} height={96.36} viewBox="0 0 127.14 96.36">
      <path
        fill="#d4d4d8"
        d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
      />
    </svg>
  );

  return (
    <div className="flex flex-col space-y-1">
      <Navlink path="https://subtrace.dev/discord" icon={<Discord />} text="Discord" />
      <Navlink path="https://docs.subtrace.dev" icon={<BookOpen />} text="Docs" />
      <Navlink path="mailto:support@subtrace.dev" icon={<MessagesSquare />} text="Support" />
    </div>
  );
}

function UserSection(): React.ReactNode {
  return (
    <div className="w-full px-4 py-2 flex justify-between">
      <button
        onClick={() => (document.location = "/api/LogOut")}
        className="-m-2 w-8 h-8 rounded-full text-zinc-500 hover:text-zinc-300 cursor-pointer flex justify-center items-center group relative"
      >
        <div className="w-[14px] h-[14px] flex items-center justify-center">
          <LogOut />
        </div>
        <div className="whitespace-pre hidden group-hover:flex absolute -top-3 left-[50%] translate-x-[-50%] text-center text-[10px] justify-center items-center">
          <span>Logout</span>
        </div>
      </button>
    </div>
  );
}

export function Navigation(): React.ReactElement {
  const [currentNamespace, setCurrentNamespace] = CustomHooks.useNamespaceState();
  const [namespaces, setNamespaces] = React.useState<Api.ListNamespaces_Item[] | undefined>(undefined);
  const [isHidden, setHidden] = React.useState<boolean>(false);

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function getNamespaces(): Promise<void> {
      try {
        const listOrgsRequest: Api.ListOrgs_Request = {};
        const listOrgsResponse: Response = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, { signal });
        await ApiUtils.assertStatus(listOrgsResponse, 200);
        const { orgs }: Api.ListOrgs_Response = await listOrgsResponse.json();
        ArrayUtils.assertLengthAtLeast(orgs, 1);

        // We currently assume that there's only one org and we're always dealing with the first one
        const orgId: string = orgs[0].orgId;
        const request: Api.ListNamespaces_Request = { orgId };
        const response: Response = await ApiUtils.post("/api/ListNamespaces", request, { signal, subtraceTags: { org_id: orgId } });
        await ApiUtils.assertStatus(response, 200);

        const { namespaces }: Api.ListNamespaces_Response = await response.json();
        setNamespaces(namespaces);
      } catch {
        if (controller.signal.aborted) {
          // Do nothing, the API call was canceled
        }
      }
    }

    getNamespaces();

    return (): void => {
      controller.abort("Cleaning up effect getNamespaces");
    };
  }, []);

  if (isHidden) {
    return (
      <div className="bg-zinc-900/50 py-4 px-3">
        <button className="h-full flex self-end text-zinc-600 hover:text-zinc-400" onClick={() => setHidden(false)} title="Show sidebar">
          <ChevronRightIcon size={15} />
        </button>
      </div>
    );
  }

  return (
    <div className="basis-1/3 grow min-w-48 max-w-60">
      <div className="w-full h-full flex flex-col justify-between py-4 px-3 bg-zinc-900/50">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-4">
            <button className="flex w-full justify-end text-zinc-600 hover:text-zinc-400" onClick={() => setHidden(true)} title="Hide sidebar">
              <ChevronLeftIcon size={15} />
            </button>
            {renderNamespaceSelector()}
          </div>
          <span className="h-[1px] bg-zinc-800" />
          <PageSelector />
        </div>
        <div className="flex flex-col space-y-4">
          <HelpLinks />
          <UserSection />
        </div>
      </div>
    </div>
  );

  function renderNamespaceSelector(): React.ReactNode {
    if (namespaces === undefined) {
      return (
        <div className="flex justify-between rounded-md text-zinc-600">
          <div className="flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs">
            <div className="w-4 h-4 flex justify-center items-center">
              <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
                <Loader2 />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center space-y-1 py-2 rounded-md font-medium text-xs text-zinc-300">
        <span className="self-start">Namespace</span>
        {namespaces.length > 0 ? (
          <Select
            ariaLabel="Select namespace"
            className="h-8 px-2 font-medium text-xs self-stretch"
            items={namespaces.map((namespace) => ({
              displayElement: namespace.name,
              itemKey: namespace.namespaceId,
              value: namespace,
            }))}
            onChange={setCurrentNamespace}
            value={StrictUtils.ensureDefined(namespaces.find((namespace) => namespace.namespaceId === currentNamespace.namespaceId))}
          />
        ) : (
          <span className="emphasis text-zinc-500">No namespace</span>
        )}
      </div>
    );
  }
}
