import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon, MaximizeIcon, HistoryIcon, XIcon, ChartNoAxesColumn } from "lucide-react";

import { DateDisplayTimeZone } from "DateDisplayTimeZone";
import { Overlay } from "Overlay";
import { Spinner } from "DesignComponents/Spinner";
import * as DateUtils from "DateUtils";
import * as MathUtils from "Utils/MathUtils";
import * as StrictUtils from "Utils/StrictUtils";
import * as RequestGraphConstants from "RequestGraphConstants";
import * as RequestGraphUtils from "RequestGraphUtils";
import * as Verify from "Utils/Verify";

export function RequestGraph(props: RequestGraphProps): React.ReactNode {
  const {
    countByTimestamp,
    displayTimeZone,
    focusedLeftTimestamp,
    focusedRightTimestamp,
    globalLeftTimestamp,
    globalRightTimestamp,
    onFocusedTimeRangeChanged,
    onGlobalTimeRangeChanged,
  } = props;

  const zeroPointForTimestampRounding: number = DateUtils.getTimeZoneOffset(displayTimeZone);

  const [dragState, setDragState] = React.useState<DragState>({ isDragging: false });
  const [mousePositionState, setMousePositionState] = React.useState<MousePositionState>({ isMouseInGraphArea: false });

  const graphRootDivRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const currentGranularity: number = RequestGraphUtils.getIdealGranularity(globalRightTimestamp - globalLeftTimestamp);

  const [prevDisplayTimeZone, setPrevDisplayTimeZone] = React.useState(displayTimeZone);
  if (displayTimeZone !== prevDisplayTimeZone) {
    setPrevDisplayTimeZone(displayTimeZone);
    onDisplayTimeZoneChanged(displayTimeZone);
  }

  React.useEffect(
    function handleMouseActivity() {
      function onDocumentMouseMove(event: MouseEvent): void {
        if (!graphRootDivRef.current) {
          return;
        }

        const graphRootBoundingRect: DOMRect = graphRootDivRef.current.getBoundingClientRect();
        const timestampFraction: number = MathUtils.getFraction(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right);
        const timestamp: number = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, timestampFraction);

        if (
          MathUtils.isBetween(
            event.clientY,
            MathUtils.getInterpolatedValue(graphRootBoundingRect.top, graphRootBoundingRect.bottom, RequestGraphConstants.PAN_HANDLE_HEIGHT_PERCENTAGE / 100),
            graphRootBoundingRect.bottom,
          ) &&
          MathUtils.isBetween(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right)
        ) {
          setMousePositionState({ isMouseInGraphArea: true, timestamp });
        } else {
          setMousePositionState({ isMouseInGraphArea: false });
        }

        if (dragState.isDragging) {
          switch (dragState.kind) {
            case DragStateKind.LeftHandle: {
              const maxPossibleLeftTimestamp: number = MathUtils.roundDownToGranularity(
                focusedRightTimestamp - RequestGraphConstants.EPSILON,
                currentGranularity,
                zeroPointForTimestampRounding,
              );
              const quantizedTimestamp: number = MathUtils.roundToGranularity(timestamp, currentGranularity, zeroPointForTimestampRounding);
              setDragState({ ...dragState, newIntervalStartTimestamp: MathUtils.clamp(quantizedTimestamp, globalLeftTimestamp, maxPossibleLeftTimestamp) });
              return;
            }

            case DragStateKind.RightHandle: {
              const minPossibleRightTimestamp: number = MathUtils.roundUpToGranularity(
                focusedLeftTimestamp + RequestGraphConstants.EPSILON,
                currentGranularity,
                zeroPointForTimestampRounding,
              );
              const quantizedTimestamp: number = MathUtils.roundToGranularity(timestamp, currentGranularity, zeroPointForTimestampRounding);
              setDragState({ ...dragState, newIntervalEndTimestamp: MathUtils.clamp(quantizedTimestamp, minPossibleRightTimestamp, globalRightTimestamp) });
              return;
            }

            case DragStateKind.Pan: {
              const windowDuration: number = focusedRightTimestamp - focusedLeftTimestamp;
              if (event.clientX - dragState.leftOffsetFromPanHandle <= graphRootBoundingRect.left) {
                // Clamp left
                setDragState({
                  ...dragState,
                  newIntervalStartTimestamp: globalLeftTimestamp,
                  newIntervalEndTimestamp: globalLeftTimestamp + windowDuration,
                });
              } else if (event.clientX + dragState.rightOffsetFromPanHandle >= graphRootBoundingRect.right) {
                // Clamp right
                setDragState({
                  ...dragState,
                  newIntervalStartTimestamp: globalRightTimestamp - windowDuration,
                  newIntervalEndTimestamp: globalRightTimestamp,
                });
              } else {
                // Clamp neither
                const windowStartTimestampFraction: number = MathUtils.getFraction(
                  event.clientX - dragState.leftOffsetFromPanHandle,
                  graphRootBoundingRect.left,
                  graphRootBoundingRect.right,
                );
                const windowStartTimestamp: number = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, windowStartTimestampFraction);
                const quantizedWindowStartTimestamp: number = MathUtils.roundToGranularity(windowStartTimestamp, currentGranularity, zeroPointForTimestampRounding);
                setDragState({
                  ...dragState,
                  newIntervalStartTimestamp: quantizedWindowStartTimestamp,
                  newIntervalEndTimestamp: quantizedWindowStartTimestamp + windowDuration,
                });
              }
              return;
            }

            case DragStateKind.NewInterval: {
              const timestampFraction: number = MathUtils.getFraction(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right);
              const timestamp: number = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, timestampFraction);
              setDragState({ ...dragState, newIntervalEndTimestamp: MathUtils.clamp(timestamp, globalLeftTimestamp, globalRightTimestamp) });
              return;
            }

            default:
              Verify.isNever(dragState);
          }
        }
      }

      function onDocumentMouseUp(): void {
        if (!graphRootDivRef.current) {
          return;
        }

        const graphRootBoundingRect: DOMRect = graphRootDivRef.current.getBoundingClientRect();
        if (dragState.isDragging && graphRootBoundingRect) {
          switch (dragState.kind) {
            case DragStateKind.LeftHandle:
              onFocusedTimeRangeChanged(dragState.newIntervalStartTimestamp, focusedRightTimestamp);
              break;

            case DragStateKind.RightHandle:
              onFocusedTimeRangeChanged(focusedLeftTimestamp, dragState.newIntervalEndTimestamp);
              break;

            case DragStateKind.Pan:
              onFocusedTimeRangeChanged(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp);
              break;

            case DragStateKind.NewInterval: {
              const newIntervalLeftTimestamp: number = MathUtils.roundDownToGranularity(
                Math.min(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp),
                currentGranularity,
                zeroPointForTimestampRounding,
              );
              const newIntervalRightTimestamp: number = MathUtils.roundUpToGranularity(
                Math.max(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp),
                currentGranularity,
                zeroPointForTimestampRounding,
              );

              onFocusedTimeRangeChanged(newIntervalLeftTimestamp, newIntervalRightTimestamp);
              break;
            }

            default:
              Verify.isNever(dragState);
          }

          setDragState({ isDragging: false });
        }
      }

      document.addEventListener("mouseup", onDocumentMouseUp);
      document.addEventListener("mousemove", onDocumentMouseMove);
      return (): void => {
        document.removeEventListener("mouseup", onDocumentMouseUp);
        document.removeEventListener("mousemove", onDocumentMouseMove);
      };
    },
    [
      currentGranularity,
      dragState,
      globalLeftTimestamp,
      globalRightTimestamp,
      onFocusedTimeRangeChanged,
      focusedLeftTimestamp,
      focusedRightTimestamp,
      zeroPointForTimestampRounding,
    ],
  );

  return (
    <div className="w-full flex flex-col items-center space-y-3">
      <div className="w-full h-24 relative flex flex-row">
        <button
          className="z-10 w-8 h-full rounded text-zinc-500 hover:text-zinc-300 flex justify-center items-center"
          onClick={() => translateTimeRange(Math.round(0.25 * RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN) * currentGranularity * -1)}
          title="Backward"
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </button>
        <div className="outline outline-[1px] outline-zinc-900 rounded relative grow" ref={graphRootDivRef}>
          {renderContent()}
        </div>
        <button
          className="z-10 w-8 h-full rounded text-zinc-500 hover:text-zinc-300 flex justify-center items-center"
          onClick={() => translateTimeRange(Math.round(0.25 * RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN) * currentGranularity)}
          title="Forward"
        >
          <ChevronRightIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="px-8 w-full flex">{renderControls()}</div>
    </div>
  );

  function onDisplayTimeZoneChanged(displayTimeZone: DateDisplayTimeZone): void {
    const zeroPointForTimestampRounding: number = DateUtils.getTimeZoneOffset(displayTimeZone);
    const newGlobalRightTimestamp: number = MathUtils.roundUpToGranularity(Math.min(globalRightTimestamp, Date.now()), currentGranularity, zeroPointForTimestampRounding);
    const newGlobalLeftTimestamp: number = newGlobalRightTimestamp - RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN * currentGranularity;
    if (newGlobalLeftTimestamp !== globalLeftTimestamp || newGlobalRightTimestamp !== globalRightTimestamp) {
      onFocusedTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp);
      onGlobalTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp, currentGranularity);
    }
  }

  function translateTimeRange(delta: number): void {
    const newGlobalLeftTimestamp: number = globalLeftTimestamp + delta;
    const newGlobalRightTimestamp: number = globalRightTimestamp + delta;

    // Reset the focused window to the entire window if it doesn't fit in the new range
    if (focusedLeftTimestamp < newGlobalLeftTimestamp || focusedRightTimestamp > newGlobalRightTimestamp) {
      onFocusedTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp);
    }

    onGlobalTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp, currentGranularity);
  }

  function renderControls(): React.ReactNode {
    const zoomGranularity: number = RequestGraphUtils.getIdealGranularity(focusedRightTimestamp - focusedLeftTimestamp);

    return (
      <div className="w-full flex justify-between items-center">
        <div className="flex select-none">
          <div className="flex items-center font-semibold text-[11px] leading-[11px] text-zinc-400 space-x-1 pr-2">
            <ChartNoAxesColumn className="w-[11px] h-[11px]" strokeWidth={3} />
            <span>Granularity</span>
          </div>
          {RequestGraphConstants.knownGranularities.map((granularity) => {
            function format(duration: number): string {
              if (duration < 60 * 1000) {
                return `${duration / 1000}s`;
              }
              if (duration < 60 * 60 * 1000) {
                return `${duration / 60 / 1000}m`;
              }
              if (duration < 24 * 60 * 60 * 1000) {
                return `${duration / 60 / 60 / 1000}h`;
              }
              return `${duration / 24 / 60 / 60 / 1000}d`;
            }

            return (
              <button
                key={granularity}
                className={[
                  `flex justify-center items-center text-[11px] leading-[11px] font-medium px-[10px] -mx-[3px] py-1 rounded-sm`,
                  currentGranularity === granularity ? "bg-zinc-800 text-zinc-400 hover:brightness-[1.10]" : "bg-transparent text-zinc-500 hover:text-zinc-400/80",
                ].join(" ")}
                onClick={() => updateDataGranularity(granularity)}
              >
                {format(granularity)}
              </button>
            );
          })}
        </div>
        <div className="flex space-x-6 select-none">
          {zoomGranularity !== currentGranularity ? (
            <button
              className="flex justify-center items-center text-[11px] leading-[11px] text-zinc-500 font-medium bg-transparent hover:text-zinc-400/80 py-1 space-x-[6px] hover:brightness-[1.10]"
              onClick={() => zoomToFocus(focusedLeftTimestamp, focusedRightTimestamp)}
            >
              <MaximizeIcon className="w-[11px] h-[11px]" strokeWidth={3} />
              <span>Zoom</span>
            </button>
          ) : null}
          {focusedLeftTimestamp !== globalLeftTimestamp || focusedRightTimestamp !== globalRightTimestamp ? (
            <button
              className="flex justify-center items-center text-[11px] leading-[11px] text-zinc-500 font-medium bg-transparent hover:text-zinc-400/80 py-1 space-x-[6px] hover:brightness-[1.10]"
              onClick={clearFocus}
            >
              <XIcon className="w-[11px] h-[11px]" strokeWidth={3} />
              <span>Clear</span>
            </button>
          ) : null}
          <button
            className="flex justify-center items-center text-[11px] leading-[11px] text-zinc-500 font-medium bg-transparent hover:text-zinc-400/80 py-1 space-x-[6px] hover:brightness-[1.10]"
            onClick={jumpToNow}
          >
            <HistoryIcon className="scale-x-[-1] w-[11px] h-[11px]" strokeWidth={3} />
            <span>Jump to now</span>
          </button>
        </div>
      </div>
    );
  }

  function updateDataGranularity(granularity: number): void {
    const newGlobalRightTimestamp: number = MathUtils.roundUpToGranularity(Date.now(), granularity, zeroPointForTimestampRounding);
    const newGlobalLeftTimestamp: number = newGlobalRightTimestamp - granularity * RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN;
    onFocusedTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp);
    onGlobalTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp, granularity);
  }

  function renderContent(): React.ReactNode {
    return (
      <React.Fragment>
        {renderMousePositionTimestampBar()}
        {renderXAxisTimestamps()}
        {renderNewIntervalDragRectangle()}
        {renderNonFocusedRegionOverlay()}
        {renderNewIntervalDragTargetOverlay()}
        {props.isLoading ? renderLoadingSpinner() : renderDataBars()}
        {renderPanHandleAndBrushes()}
        {renderAdditionalTimestampInfo()}
      </React.Fragment>
    );
  }

  function renderDataBars(): React.ReactNode {
    const maxCount: number = Math.max(0, ...countByTimestamp.values());

    let leftTimestamp: number, rightTimestamp: number;

    if (!dragState.isDragging || dragState.kind === DragStateKind.NewInterval) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.LeftHandle) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.RightHandle) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else if (dragState.kind === DragStateKind.Pan) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else {
      Verify.isNever(dragState);
    }

    return (
      <div id="dataBars">
        {[...countByTimestamp].map(([timestamp, count]) => {
          if (timestamp < globalLeftTimestamp || timestamp + currentGranularity > globalRightTimestamp) {
            return null;
          }

          return (
            <div
              className={`rounded-[2px] border-collapse border-t border-x border-zinc-900/50 bg-sky-900 cursor-pointer hover:brightness-110 ${timestamp >= leftTimestamp && timestamp + currentGranularity <= rightTimestamp ? "border-t-2 border-t-sky-600" : "border-t-1 border-t-sky-600/0"}`}
              key={timestamp}
              onClick={() => zoomToFocus(timestamp, timestamp + currentGranularity)}
              style={{
                position: "absolute",
                left: `${MathUtils.getFraction(timestamp, globalLeftTimestamp, globalRightTimestamp) * 100}%`,
                height: `${(count * RequestGraphConstants.TALLEST_BAR_HEIGHT_PERCENTAGE) / maxCount}%`,
                bottom: 0,
                width: `${(1 / RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN) * 100}%`,
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderNewIntervalDragTargetOverlay(): React.ReactNode {
    if (!graphRootDivRef.current) {
      return null;
    }
    const graphRootBoundingRect: DOMRect = graphRootDivRef.current.getBoundingClientRect();

    return (
      <div
        id="dragTargetOverlay"
        className="bg-transparent cursor-text"
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        onMouseDown={(event) => {
          const timestampFraction: number = MathUtils.getFraction(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right);
          const timestamp: number = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, timestampFraction);
          setDragState({ isDragging: true, kind: DragStateKind.NewInterval, newIntervalEndTimestamp: timestamp, newIntervalStartTimestamp: timestamp });
        }}
      />
    );
  }

  function renderLoadingSpinner(): React.ReactNode {
    return (
      <Overlay className="absolute inset-0 flex flex-row justify-center items-center">
        <Spinner />
      </Overlay>
    );
  }

  function renderNewIntervalDragRectangle(): React.ReactNode {
    if (!dragState.isDragging || dragState.kind !== DragStateKind.NewInterval) {
      return null;
    }

    const newIntervalLeftTimestamp: number = MathUtils.roundDownToGranularity(
      Math.min(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp),
      currentGranularity,
      zeroPointForTimestampRounding,
    );
    const newIntervalRightTimestamp: number = MathUtils.roundUpToGranularity(
      Math.max(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp),
      currentGranularity,
      zeroPointForTimestampRounding,
    );
    const newIntervalStartTimestampFraction: number = MathUtils.getFraction(newIntervalLeftTimestamp, globalLeftTimestamp, globalRightTimestamp);
    const newIntervalEndTimestampFraction: number = MathUtils.getFraction(newIntervalRightTimestamp, globalLeftTimestamp, globalRightTimestamp);

    return (
      <div
        id="dragRectangle"
        className="bg-zinc-800/60"
        style={{
          position: "absolute",
          left: `${Math.min(newIntervalStartTimestampFraction, newIntervalEndTimestampFraction) * 100}%`,
          right: `${(1 - Math.max(newIntervalStartTimestampFraction, newIntervalEndTimestampFraction)) * 100}%`,
          top: 0,
          bottom: 0,
        }}
      />
    );
  }

  function renderNonFocusedRegionOverlay(): React.ReactNode {
    let leftTimestamp: number, rightTimestamp: number;

    if (!dragState.isDragging || dragState.kind === DragStateKind.NewInterval) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.LeftHandle) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.RightHandle) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else if (dragState.kind === DragStateKind.Pan) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else {
      Verify.isNever(dragState);
    }

    const focusedLeftTimestampFraction: number = MathUtils.getFraction(leftTimestamp, globalLeftTimestamp, globalRightTimestamp);
    const focusedRightTimestampFraction: number = MathUtils.getFraction(rightTimestamp, globalLeftTimestamp, globalRightTimestamp);

    return (
      <React.Fragment>
        <div
          id="nonFocusedRegionLeft"
          className="bg-zinc-800/50"
          style={{
            position: "absolute",
            left: 0,
            right: `${(1 - focusedLeftTimestampFraction) * 100}%`,
            top: 0,
            bottom: 0,
          }}
        />
        <div
          id="nonFocusedRegionRight"
          className="bg-zinc-800/50"
          style={{
            position: "absolute",
            left: `${focusedRightTimestampFraction * 100}%`,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        />
      </React.Fragment>
    );
  }

  function renderPanHandleAndBrushes(): React.ReactNode {
    let leftTimestamp: number, rightTimestamp: number;

    if (!dragState.isDragging || dragState.kind === DragStateKind.NewInterval) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.LeftHandle) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.RightHandle) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else if (dragState.kind === DragStateKind.Pan) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else {
      Verify.isNever(dragState);
    }

    return (
      <React.Fragment>
        <div
          id="panHandle"
          className={dragState.isDragging && dragState.kind === DragStateKind.Pan ? "cursor-grabbing" : "cursor-grab"}
          style={{
            position: "absolute",
            left: `${MathUtils.getFraction(leftTimestamp, globalLeftTimestamp, globalRightTimestamp) * 100}%`,
            right: `${(1 - MathUtils.getFraction(rightTimestamp, globalLeftTimestamp, globalRightTimestamp)) * 100}%`,
            top: 0,
            height: `${RequestGraphConstants.PAN_HANDLE_HEIGHT_PERCENTAGE}%`,
          }}
          onMouseDown={(event) => {
            setDragState({
              isDragging: true,
              kind: DragStateKind.Pan,
              newIntervalStartTimestamp: focusedLeftTimestamp,
              newIntervalEndTimestamp: focusedRightTimestamp,
              leftOffsetFromPanHandle: event.nativeEvent.offsetX,
              rightOffsetFromPanHandle: event.currentTarget.getBoundingClientRect().width - event.nativeEvent.offsetX,
            });
          }}
        />
        <div
          id="leftBrushHandle"
          className="bg-transparent hover:bg-zinc-700/60 w-[8px] -mx-[3px] px-[3px] group cursor-col-resize flex justify-center"
          style={{
            position: "absolute",
            left: `${MathUtils.getFraction(leftTimestamp, globalLeftTimestamp, globalRightTimestamp) * 100}%`,
            top: 0,
            bottom: 0,
          }}
          onMouseDown={() => {
            setDragState({ isDragging: true, kind: DragStateKind.LeftHandle, newIntervalStartTimestamp: leftTimestamp });
          }}
        >
          <div className="bg-zinc-700 group-hover:bg-zinc-600 w-[2px] h-full" />
        </div>
        <div
          id="rightBrushHandle"
          className="bg-transparent hover:bg-zinc-700/60 w-[8px] -mx-[3px] px-[3px] group cursor-col-resize flex justify-center"
          style={{
            position: "absolute",
            right: `${(1 - MathUtils.getFraction(rightTimestamp, globalLeftTimestamp, globalRightTimestamp)) * 100}%`,
            top: 0,
            bottom: 0,
          }}
          onMouseDown={() => {
            setDragState({ isDragging: true, kind: DragStateKind.RightHandle, newIntervalEndTimestamp: focusedRightTimestamp });
          }}
        >
          <div className="bg-zinc-700 group-hover:bg-zinc-600 w-[2px] h-full" />
        </div>
      </React.Fragment>
    );
  }

  function renderMousePositionTimestampBar(): React.ReactNode {
    if (!mousePositionState.isMouseInGraphArea || dragState.isDragging) {
      return null;
    }

    const leftEdgeTimestampFraction: number = MathUtils.getFraction(
      MathUtils.roundDownToGranularity(mousePositionState.timestamp, currentGranularity, zeroPointForTimestampRounding),
      globalLeftTimestamp,
      globalRightTimestamp,
    );

    return (
      <svg id="mousePositionTimestampBar" style={{ position: "absolute" }} width="100%" height="100%" color="transparent">
        <rect x={`${leftEdgeTimestampFraction * 100}%`} y={0} height="100%" width={`${(1 / RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN) * 100}%`} fill="#ffffff10" />
      </svg>
    );
  }

  function renderAdditionalTimestampInfo(): React.ReactNode {
    if (!dragState.isDragging) {
      if (!mousePositionState.isMouseInGraphArea) {
        return null;
      }

      const quantizedMousePositionTimestamp: number = MathUtils.roundDownToGranularity(mousePositionState.timestamp, currentGranularity, zeroPointForTimestampRounding);
      const timestampFraction: number = MathUtils.getFraction(quantizedMousePositionTimestamp, globalLeftTimestamp, globalRightTimestamp);
      return (
        <span
          className="leading-[11px] text-[11px] font-medium tracking-tighter text-zinc-400 whitespace-nowrap select-none"
          id="currentMousePositionTimestamp"
          style={{ position: "absolute", left: `${timestampFraction * 100}%`, top: 0, transform: "translate(-50%, calc(-100% - 5px))" }}
        >
          {DateUtils.formatTimestamp(new Date(quantizedMousePositionTimestamp), displayTimeZone)}
        </span>
      );
    }

    let leftTimestamp: number, rightTimestamp: number;

    if (!dragState.isDragging) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.LeftHandle) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = focusedRightTimestamp;
    } else if (dragState.kind === DragStateKind.RightHandle) {
      leftTimestamp = focusedLeftTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else if (dragState.kind === DragStateKind.Pan) {
      leftTimestamp = dragState.newIntervalStartTimestamp;
      rightTimestamp = dragState.newIntervalEndTimestamp;
    } else if (dragState.kind === DragStateKind.NewInterval) {
      leftTimestamp = MathUtils.roundDownToGranularity(
        Math.min(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp),
        currentGranularity,
        zeroPointForTimestampRounding,
      );
      rightTimestamp = MathUtils.roundUpToGranularity(
        Math.max(dragState.newIntervalStartTimestamp, dragState.newIntervalEndTimestamp),
        currentGranularity,
        zeroPointForTimestampRounding,
      );
    } else {
      Verify.isNever(dragState);
    }

    const leftTimestampFraction: number = MathUtils.getFraction(leftTimestamp, globalLeftTimestamp, globalRightTimestamp);
    const rightTimestampFraction: number = MathUtils.getFraction(rightTimestamp, globalLeftTimestamp, globalRightTimestamp);
    return (
      <React.Fragment>
        <span
          className="leading-[11px] text-[11px] font-medium tracking-tighter text-zinc-600 whitespace-nowrap select-none"
          style={{ position: "absolute", left: `${leftTimestampFraction * 100}%`, top: 0, transform: "translate(calc(-100%), calc(-100% - 5px))" }}
        >
          {DateUtils.formatTimestamp(new Date(leftTimestamp), displayTimeZone)}
        </span>
        <span
          className="leading-[11px] text-[11px] font-medium tracking-tighter text-zinc-600 whitespace-nowrap select-none"
          style={{ position: "absolute", left: `${rightTimestampFraction * 100}%`, top: 0, transform: "translate(0, calc(-100% - 5px))" }}
        >
          {DateUtils.formatTimestamp(new Date(rightTimestamp), displayTimeZone)}
        </span>
      </React.Fragment>
    );
  }

  function renderXAxisTimestamps(): React.ReactNode {
    const range: number = globalRightTimestamp - globalLeftTimestamp;

    const knownTicks = new Map<number, (date: Date) => string>();
    [1, 5, 15, 30].forEach((n) => knownTicks.set(n * 1000, (date) => DateUtils.formatTimeHHMMSS(date, displayTimeZone)));
    [1, 5, 15, 30, 60].forEach((n) => knownTicks.set(n * 60 * 1000, (date) => DateUtils.formatTimeHHMM(date, displayTimeZone)));
    [3, 4, 6, 12].forEach((n) =>
      knownTicks.set(n * 60 * 60 * 1000, (date) =>
        DateUtils.getHours(date, displayTimeZone) > 0 ? DateUtils.formatTimeHHMM(date, displayTimeZone) : DateUtils.formatDate(date, displayTimeZone),
      ),
    );
    [1, 3, 7, 30].forEach((n) => knownTicks.set(n * 24 * 60 * 60 * 1000, (d) => DateUtils.formatDate(d, displayTimeZone)));

    const idealNumTicks = 12;
    let bestTickSize = 60 * 60 * 1000;
    for (const tickSize of knownTicks.keys()) {
      const numTicks = Math.round(range / tickSize);
      if (Math.abs(numTicks - idealNumTicks) < Math.abs(Math.round(range / bestTickSize) - idealNumTicks)) {
        bestTickSize = tickSize;
      }
    }

    const ticks: number[] = [];

    let currentTick: number = MathUtils.roundDownToGranularity(globalLeftTimestamp, bestTickSize, zeroPointForTimestampRounding);
    while (currentTick < globalRightTimestamp + bestTickSize) {
      ticks.push(currentTick);
      currentTick += bestTickSize;
    }

    return (
      <div id="xAxisTimestamps" className="absolute top-0 left-0 w-full h-full leading-[11px] text-[11px] text-zinc-600 font-medium tracking-tighter overflow-x-hidden">
        {ticks.map((timestamp) => (
          <span
            className="absolute top-0 h-full text-nowrap border-l border-1 border-zinc-800 flex justify-begin items-begin select-none"
            key={timestamp}
            style={{
              width: `calc(100% * ${bestTickSize} / ${range})`,
              left: `calc(100% * ${timestamp - globalLeftTimestamp} / ${range})`,
            }}
          >
            <span className="pl-[3px] pt-[3px]">{StrictUtils.ensureDefined(knownTicks.get(bestTickSize))(new Date(timestamp))}</span>
          </span>
        ))}
      </div>
    );
  }

  function clearFocus(): void {
    onFocusedTimeRangeChanged(globalLeftTimestamp, globalRightTimestamp);
  }

  function zoomToFocus(left: number, right: number): void {
    const newGranularity = RequestGraphUtils.getIdealGranularity(right - left);
    const numberOfFocusedBarsAfterZoom: number = (right - left) / newGranularity;
    const numberOfUnfocusedBarsAfterZoom: number = RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN - numberOfFocusedBarsAfterZoom;
    const numberOfUnfocusedBarsToTheRightAfterZoom: number = Math.round(
      (numberOfUnfocusedBarsAfterZoom * (globalRightTimestamp - right)) / (globalRightTimestamp - globalLeftTimestamp - (right - left)),
    );
    const numberOfUnfocusedBarsToTheLeftAfterZoom: number = numberOfUnfocusedBarsAfterZoom - numberOfUnfocusedBarsToTheRightAfterZoom;

    const newGlobalLeftTimestamp: number = left - newGranularity * numberOfUnfocusedBarsToTheLeftAfterZoom;
    const newGlobalRightTimestamp: number = newGlobalLeftTimestamp + RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN * newGranularity;

    onGlobalTimeRangeChanged(newGlobalLeftTimestamp, newGlobalRightTimestamp, newGranularity);
  }

  function jumpToNow(): void {
    const newGlobalRightTimestamp: number = MathUtils.roundUpToGranularity(Date.now(), currentGranularity, zeroPointForTimestampRounding);
    translateTimeRange(newGlobalRightTimestamp - globalRightTimestamp);
  }
}

export interface RequestGraphProps {
  displayTimeZone: DateDisplayTimeZone;
  focusedLeftTimestamp: number;
  focusedRightTimestamp: number;
  globalLeftTimestamp: number;
  globalRightTimestamp: number;
  countByTimestamp: Map<number, number>;
  isLoading: boolean;

  onFocusedTimeRangeChanged: (startTimestamp: number, endTimestamp: number) => void;
  onGlobalTimeRangeChanged: (startTimestamp: number, endTimestamp: number, granularityInMilliseconds: number) => void;
}

type DragState =
  | {
      isDragging: false;
    }
  | {
      isDragging: true;
      kind: DragStateKind.LeftHandle;
      newIntervalStartTimestamp: number;
    }
  | {
      isDragging: true;
      kind: DragStateKind.RightHandle;
      newIntervalEndTimestamp: number;
    }
  | {
      isDragging: true;
      kind: DragStateKind.NewInterval;
      newIntervalStartTimestamp: number;
      newIntervalEndTimestamp: number;
    }
  | {
      isDragging: true;
      kind: DragStateKind.Pan;
      newIntervalStartTimestamp: number;
      newIntervalEndTimestamp: number;
      leftOffsetFromPanHandle: number;
      rightOffsetFromPanHandle: number;
    };

type MousePositionState =
  | {
      isMouseInGraphArea: false;
    }
  | {
      isMouseInGraphArea: true;
      timestamp: number;
    };

const enum DragStateKind {
  LeftHandle = "LeftHandle",
  RightHandle = "RightHandle",
  Pan = "Pan",
  NewInterval = "NewInterval",
}
