export function formatForDisplay(durationInNanoseconds: number | undefined): string {
  if (!durationInNanoseconds) {
    return "";
  }

  // The left shift operator doesn't work here since it assumes a 32 bit underlying integer.
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Left_shift#description
  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (durationInNanoseconds >= 1e9 * 60 * 60 * 24) {
    return `${formatter.format(durationInNanoseconds / (1e9 * 60 * 60 * 24))}d`;
  } else if (durationInNanoseconds >= 1e9 * 60 * 60) {
    return `${formatter.format(durationInNanoseconds / (1e9 * 60 * 60))}h`;
  } else if (durationInNanoseconds >= 1e9 * 60) {
    return `${formatter.format(durationInNanoseconds / (1e9 * 60))}m`;
  } else if (durationInNanoseconds >= 1e9) {
    return `${formatter.format(durationInNanoseconds / 1e9)}s`;
  } else if (durationInNanoseconds >= 1e6) {
    return `${formatter.format(durationInNanoseconds / 1e6)}ms`;
  } else if (durationInNanoseconds >= 1e3) {
    return `${formatter.format(durationInNanoseconds / 1e3)}us`;
  } else {
    return `${durationInNanoseconds}ns`;
  }
}
