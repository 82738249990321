import * as React from "react";

import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as EventLogger from "EventLogger";
import * as JsonUtils from "JsonUtils";
import * as StyleUtils from "Utils/StyleUtils";
import * as Verify from "Utils/Verify";
import AWSLogo from "../images/aws_logo.svg";
import AzureLogo from "../images/azure_logo.svg";
import DockerLogo from "../images/docker_logo.svg";
import GCPLogo from "../images/gcp_logo.svg";
import GoLogo from "../images/go_logo.svg";
import JavaLogo from "../images/java_logo.svg";
import KubernetesLogo from "../images/kubenetes_logo.svg";
import NodejsLogo from "../images/nodejs_logo.svg";
import PhpLogo from "../images/php_logo.svg";
import ProductDemoImage from "../images/product_demo.png";
import RubyLogo from "../images/ruby_logo.svg";
import RustLogo from "../images/rust_logo.svg";
import PythonLogo from "../images/python_logo.svg";
import { Button } from "DesignComponents/Button";
import { ExternalLink } from "DesignComponents/ExternalLink";
import { InternalLink } from "DesignComponents/InternalLink";
import { SubtraceEventKind } from "SubtraceEventKind";
import { TextField } from "DesignComponents/TextField";
import { User } from "User";

function getRandomLanguage(seed: number): string {
  const choices: Record<string, number> = {
    "for Python apps": 0.5,
    "for Node.js servers": 0.3,
    "for Go backends": 0.2,
  };

  let total = 0;
  for (const [choice, weight] of Object.entries(choices)) {
    if (total + weight >= seed) {
      return choice;
    }
    total += weight;
  }

  throw new Error("unreachable");
}

function TitleText(): React.ReactElement {
  const seed = React.useState<number>(Math.random())[0];
  const texts: string[] = ["for Kubernetes pods", `${getRandomLanguage(seed)}`, "for virtual machines", "for Docker containers", "that just works"];

  const [textIndex, setTextIndex] = React.useState<number>(0);
  const [textFade, setTextFade] = React.useState<string>("opacity-100 translate-y-[0rem]");

  React.useEffect(() => {
    let interval: number;
    if (textIndex !== texts.length - 1) {
      interval = setInterval(() => {
        setTextFade("translate-y-[1rem] opacity-0 blur-[1rem]");
        setTimeout(() => {
          setTextFade("-translate-y-[1rem] opacity-0 blur-[1rem]");
          setTimeout(() => {
            setTextFade("");
            setTextIndex((textIndex + 1) % texts.length);
          }, 75);
        }, 75);
      }, /* milliseconds */ 3000);
    }

    return (): void => {
      clearInterval(interval);
    };
  }, [textIndex, texts.length]);

  return (
    <div className="mt-20 flex justify-center">
      <h2 className="font-semibold text-6xl max-w-3xl flex flex-col items-center p-4 text-zinc-100">
        <span>Automatic tracing</span>
        <span className={`mt-2 transition-all duration-[0.05s] ease-in-out ${textFade}`}>
          <span>{texts[textIndex]}</span>
        </span>
      </h2>
    </div>
  );
}

function PolkaDots(props: { color: string }): React.ReactElement {
  return (
    <div
      style={{
        backgroundImage: `radial-gradient(#ffffff0d 10%, transparent 10%), radial-gradient(#ffffff0d 10%, transparent 10%)`,
        backgroundPosition: "0px 0px, 8px 8px",
        backgroundSize: "16px 16px",
      }}
      className={`pointer-events-none overflow-hidden absolute left-0 top-0 w-full h-full ${props.color}`}
    />
  );
}

function Orbit(props: { children: React.ReactElement[]; radius: number; numberOfItems: number }): React.ReactElement | null {
  const size = props.children.length ?? 1;
  const { numberOfItems } = props;

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full">
      <div
        className={StyleUtils.mergeClassNames(
          "pointer-events-auto relative rounded-full border border-dashed border-zinc-700",
          props.radius > 300 ? "animate-spin-slow-2" : "animate-spin-slow-1",
          props.radius > 300 ? "rotate-90" : "",
        )}
        style={{
          top: `calc(120% - ${props.radius}px)`,
          left: `calc(50% - ${props.radius}px)`,
          width: `${2 * props.radius}px`,
          height: `${2 * props.radius}px`,
        }}
      >
        {ArrayUtils.extendByRepetition(props.children, numberOfItems).map((_element, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              transform: `rotate(${90 - (i * 360) / numberOfItems}deg)`,
              left: `calc(50% - 16px + ${props.radius * Math.cos((i * 2 * Math.PI) / numberOfItems)}px)`,
              top: `calc(50% - 16px - ${props.radius * Math.sin((i * 2 * Math.PI) / numberOfItems)}px)`,
            }}
          >
            {props.children[i % size]}
          </div>
        ))}
      </div>
    </div>
  );
}

function Integrations(): React.ReactElement {
  function Logo(props: { name: string; src: string }): React.ReactElement {
    return (
      <div className="w-7 h-7 group relative">
        <div className="absolute w-full h-full flex justify-center items-center">
          <img
            className="absolute w-full transition ease-in-out contrast-[0.50] saturation-[2.00] group-hover:saturation-[3.00] grayscale brightness-[0.75] group-hover:brightness-[1.25]"
            src={props.src}
            alt={props.name}
          />
        </div>
        <span className="whitespace-pre hidden group-hover:block select-none absolute left-[50%] translate-x-[-50%] -top-7 text-center text-[10px] font-medium leading-[10px] bg-zinc-800 text-zinc-400 px-2 py-1 rounded-sm border border-zinc-700">
          {props.name}
        </span>
      </div>
    );
  }

  return (
    <div className="overflow-hidden absolute left-0 top-0 w-full h-full">
      <div className="relative top-0 left-0 w-full h-full">
        <div
          className="absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem]"
          style={{
            background: "radial-gradient(#07598560 0%, #07598540 25%, transparent 50%, transparent 100%)",
          }}
        />
        <Orbit radius={360} numberOfItems={16}>
          <Logo name="Node.js" src={NodejsLogo} />
          <Logo name="Go" src={GoLogo} />
          <Logo name="Ruby" src={RubyLogo} />
          <Logo name="Java" src={JavaLogo} />
          <Logo name="PHP" src={PhpLogo} />
          <Logo name="Rust" src={RustLogo} />
          <Logo name="Python" src={PythonLogo} />
        </Orbit>
        <Orbit radius={240} numberOfItems={12}>
          <Logo name="Docker" src={DockerLogo} />
          <Logo name="Kubernetes" src={KubernetesLogo} />
          <Logo name="AWS" src={AWSLogo} />
          <Logo name="Azure" src={AzureLogo} />
          <Logo name="Google Cloud" src={GCPLogo} />
        </Orbit>
      </div>
    </div>
  );
}

export function LandingPage(): React.ReactElement {
  const currentUser: User | undefined = CustomHooks.useCurrentUser();
  const [emailAddress1, setEmailAddress1] = React.useState<string>("");
  const [emailAddress2, setEmailAddress2] = React.useState<string>("");
  const [emailAddress1SubmitState, setEmailAddress1SubmitState] = React.useState<EmailSubmitState>(EmailSubmitState.Unsubmitted);
  const [emailAddress2SubmitState, setEmailAddress2SubmitState] = React.useState<EmailSubmitState>(EmailSubmitState.Unsubmitted);

  return (
    <div className="w-full h-vh flex flex-col">
      <div className="flex justify-center mt-12">
        <div className="w-full max-w-6xl py-2">
          <div className="float-left my-auto flex items-center space-x-4">
            <InternalLink className="px-4 py-2 mr-4 text-md tracking-widest font-mono text-white" label="subtrace" target="/" />
            <ExternalLink className="px-4 py-2 text-sm" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="px-4 py-2 text-sm" label="Discord" openInNewTab target="https://discord.gg/GXuwMJXGUk" />
          </div>
          {renderTopRightButtons()}
        </div>
      </div>

      <TitleText />

      <div className="mt-8 flex justify-center">
        <div className="text-zinc-300 max-w-md text-center">Subtrace is the easiest way to add tracing to your infra. Works out of the box. Built for developers.</div>
      </div>

      <div className="mt-16 flex justify-center">
        <div className="w-full max-w-md flex flex-col justify-end items-center space-y-4">
          <span className="text-zinc-300">Interested to know more?</span>
          <div className="w-full space-x-5 flex flex-row flex-nowrap justify-center h-9">
            {emailAddress1SubmitState === EmailSubmitState.Success ? (
              <span className="text-zinc-300 self-center">Thanks! We&apos;ll be in touch.</span>
            ) : (
              <React.Fragment>
                <TextField classNames={{ root: "grow" }} onChange={setEmailAddress1} placeholder="Email" type="email" value={emailAddress1} />
                <Button
                  className="w-fit"
                  disabled={emailAddress1SubmitState === EmailSubmitState.Submitting}
                  label="Try for free"
                  onClick={() => submitEmail(EmailSubmitLocation.Upper)}
                  showArrow
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <div className="m-40 mt-20 flex justify-center">
        <img className="max-w-5xl" src={ProductDemoImage} alt="Dashboard" />
      </div>

      <div className="mt-16 flex justify-center">
        <div className="flex flex-col space-y-8">
          <div className="flex space-x-8 items-center text-xl font-medium">
            <div className="relative overflow-hidden w-full h-96 px-8 py-6 bg-zinc-900/70 rounded-xl">
              <span className="text-zinc-300">Works the same everywhere.</span>
              <span> </span>
              <span className="text-zinc-400">Every language. Every cloud.</span>
              <Integrations />
            </div>
          </div>

          <div className="flex space-x-8 items-center text-lg font-medium">
            <div className="relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Bring your own storage.</span>
              <span> </span>
              <span className="text-zinc-400">Regain control over your company&apos;s data security. Your tracing data stays in your own servers.</span>
            </div>
            <div className="relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Zero config.</span>
              <span> </span>
              <span className="text-zinc-400">Subtrace fits into your existing backend out of the box. No code changes required.</span>
            </div>
          </div>

          <div className="flex space-x-8 items-center text-sm">
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Made for startups.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Spend more time on what&apos;s important for your product.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Move fast, search things.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Subtrace is built on ClickHouse, the fastest database for analytics.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Fixed pricing.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Get a predictable bill every month. No surprises.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Open source.</span>
              <span> </span>
              <ExternalLink className="font-normal text-zinc-300/95" label="Star us on GitHub." target="https://github.com/subtrace/subtrace" />
            </div>
          </div>

          <div className="w-full h-24 flex p-4 bg-zinc-900 rounded-xl">
            <div className="w-full flex flex-row items-center justify-between">
              <div className="text-xl font-medium flex items-center text-zinc-200">
                <span className="text-white">Interested?</span>&nbsp;
                <span className="text-zinc-400">Schedule a demo.</span>
              </div>
              {emailAddress2SubmitState === EmailSubmitState.Success ? (
                <span className="text-zinc-400 font-medium text-xl">Thanks! We&apos;ll be in touch.</span>
              ) : (
                <div className="relative flex flex-row space-x-5 h-9">
                  <TextField classNames={{ root: "grow" }} onChange={setEmailAddress2} placeholder="Email" type="email" value={emailAddress2} />
                  <Button
                    disabled={emailAddress2SubmitState === EmailSubmitState.Submitting}
                    label="Try for free"
                    onClick={() => submitEmail(EmailSubmitLocation.Lower)}
                    showArrow
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 flex justify-center">
        <div className="w-full max-w-3xl py-2 mt-16 mb-32">
          <div className="flex items-center space-x-8">
            <span className="text-[13px] pointer-events-none tracking-wide rounded font-semibold text-zinc-600 font-mono mr-4">subtrace</span>
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Discord" openInNewTab target="https://discord.gg/GXuwMJXGUk" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="GitHub" openInNewTab target="https://github.com/subtrace/subtrace" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Support" target="mailto:support@subtrace.dev" />
          </div>
        </div>
      </div>
    </div>
  );

  function renderTopRightButtons(): React.ReactElement | null {
    if (!currentUser) {
      return null;
    }

    return (
      <div className="float-right my-auto flex items-center space-x-4">
        {currentUser.isLoggedIn ? (
          <InternalLink className="px-4 py-2" label="Dashboard" showArrow showAsButton target="/dashboard" />
        ) : (
          <React.Fragment>
            <InternalLink className="px-4 py-2" label="Log in" target="/login" />
            <ExternalLink className="px-4 py-2" label="Schedule demo" openInNewTab target="https://cal.com/subtrace/demo" showArrow showAsButton />
          </React.Fragment>
        )}
      </div>
    );
  }

  async function submitEmail(location: EmailSubmitLocation): Promise<void> {
    try {
      let email: string;
      switch (location) {
        case EmailSubmitLocation.Upper:
          email = emailAddress1;
          setEmailAddress1SubmitState(EmailSubmitState.Submitting);
          break;

        case EmailSubmitLocation.Lower:
          email = emailAddress2;
          setEmailAddress2SubmitState(EmailSubmitState.Submitting);
          break;

        default:
          Verify.isNever(location);
      }

      const response: Response = await fetch("https://subtrace.dev/email", {
        method: "POST",
        body: JSON.stringify({ email, location }),
        credentials: "include",
      });
      ApiUtils.assertStatus(response, 200);

      switch (location) {
        case EmailSubmitLocation.Upper:
          setEmailAddress1SubmitState(EmailSubmitState.Success);
          break;

        case EmailSubmitLocation.Lower:
          setEmailAddress2SubmitState(EmailSubmitState.Success);
          break;

        default:
          Verify.isNever(location);
      }
    } catch (error: unknown) {
      switch (location) {
        case EmailSubmitLocation.Upper:
          setEmailAddress1SubmitState(EmailSubmitState.Failure);
          break;

        case EmailSubmitLocation.Lower:
          setEmailAddress2SubmitState(EmailSubmitState.Failure);
          break;

        default:
          Verify.isNever(location);
      }

      const errorToLog: Error = error instanceof Error ? error : new Error(JsonUtils.stringify(error));
      EventLogger.logEvent(SubtraceEventKind.EmailSubmitError, {
        error_message: errorToLog.message,
        error_name: errorToLog.name,
        error_stack: errorToLog.stack ?? "",
        location,
      });
    }
  }
}

const enum EmailSubmitLocation {
  Lower = "Lower",
  Upper = "Upper",
}

const enum EmailSubmitState {
  Failure = "Failure",
  Success = "Success",
  Submitting = "Submitting",
  Unsubmitted = "Unsubmitted",
}
