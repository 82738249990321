import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { MoveDownLeftIcon, MoveUpRightIcon, DeleteIcon } from "lucide-react";
import { type ColDef, type RowDoubleClickedEvent, type ValueFormatterFunc } from "ag-grid-community";
import { type CustomCellRendererProps } from "ag-grid-react";

import * as CustomHooks from "CustomHooks";
import * as DateUtils from "DateUtils";
import * as DurationUtils from "DurationUtils";
import * as EventLogger from "EventLogger";
import * as JsonUtils from "JsonUtils";
import * as MathUtils from "Utils/MathUtils";
import * as QueryUtils from "QueryUtils";
import * as RequestGraphConstants from "RequestGraphConstants";
import * as StrictUtils from "Utils/StrictUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import { Clause } from "Clause";
import { ClauseEditor, ClauseEditorImperativeProps } from "ClauseEditor";
import { DateDisplayTimeZone } from "DateDisplayTimeZone";
import { GraphQuery } from "GraphQuery";
import { GraphQueryColumnName } from "GraphQueryColumnName";
import { GridQuery } from "GridQuery";
import { LazyGrid } from "LazyGrid";
import { PinnedColumnCellRenderer } from "PinnedColumnCellRenderer";
import { QueryResult, QueryResultRow } from "QueryResult";
import { RequestDetailsSidePanel } from "RequestDetailsSidePanel";
import { RequestGraph } from "RequestGraph";
import { Spinner } from "DesignComponents/Spinner";
import { SubtraceEventKind } from "SubtraceEventKind";
import { QueryManager } from "QueryManager";
import { Toggle } from "Toggle";
import { UrlState } from "UrlState";

const filterSearchParamName: string = "filter";
const eventIdColumnName: string = SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.event_id);

export function RequestsPage(): React.ReactNode {
  const [{ namespaceId }] = CustomHooks.useNamespaceState();

  const [searchParams, setSearchParams] = ReactRouterDom.useSearchParams();
  const initialFilterSearchParam: string | null = searchParams.get(filterSearchParamName);

  const [gridColumnNames, setGridColumnNames] = React.useState<string[]>(
    [
      SubtraceEvent.KnownFields.time,
      SubtraceEvent.KnownFields.event_id,
      SubtraceEvent.KnownFields.http_req_method,
      SubtraceEvent.KnownFields.http_req_path,
      SubtraceEvent.KnownFields.http_resp_status_code,
    ].map((field) => SubtraceEvent.knownFieldsToJSON(field)),
  );
  const [pinnedRows, setPinnedRows] = React.useState<QueryResultRow[]>([]);
  const clauseEditorRef: React.MutableRefObject<ClauseEditorImperativeProps | null> = React.useRef(null);

  let urlState: UrlState | undefined = undefined;
  try {
    if (initialFilterSearchParam != null) {
      urlState = JsonUtils.parse(atob(initialFilterSearchParam));
    }
  } catch {
    // Do nothing, the filter is likely not valid JSON
  }

  const [displayTimeZone, setDisplayTimeZone] = React.useState<DateDisplayTimeZone>(DateDisplayTimeZone.UTC);

  const initialGraphGlobalRightTimestamp: number =
    urlState?.globalTimestampFilter?.upperBoundTimestamp ??
    MathUtils.roundUpToGranularity(Date.now(), RequestGraphConstants.DEFAULT_GRANULARITY, DateUtils.getTimeZoneOffset(displayTimeZone));
  const initialGraphGlobalLeftTimestamp: number =
    urlState?.globalTimestampFilter?.lowerBoundTimestamp ??
    initialGraphGlobalRightTimestamp - RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN * RequestGraphConstants.DEFAULT_GRANULARITY;
  const initialGraphFocusedRightTimestamp: number = urlState?.focusedTimestampFilter?.upperBoundTimestamp ?? initialGraphGlobalRightTimestamp;
  const initialGraphFocusedLeftTimestamp: number = urlState?.focusedTimestampFilter?.lowerBoundTimestamp ?? initialGraphGlobalLeftTimestamp;

  const [evaluatingGridQuery, setEvaluatingGridQuery] = React.useState<GridQuery | undefined>({
    clauses: urlState?.clauses ?? [],
    timestampFilter: {
      lowerBoundTimestamp: initialGraphFocusedLeftTimestamp,
      upperBoundTimestamp: initialGraphFocusedRightTimestamp,
    },
  });
  const [appliedGridQuery, setAppliedGridQuery] = React.useState<GridQuery | undefined>(undefined);
  const [gridQueryResult, setGridQueryResult] = React.useState<QueryResult | undefined>(undefined);
  const evaluatingGridQueryId: React.MutableRefObject<string> = React.useRef(crypto.randomUUID());

  const [graphCountByTimestamp, setGraphCountByTimestamp] = React.useState<Map<number, number>>(new Map());
  const [graphGlobalLeftTimestamp, setGraphGlobalLeftTimestamp] = React.useState<number>(initialGraphGlobalLeftTimestamp);
  const [graphGlobalRightTimestamp, setGraphGlobalRightTimestamp] = React.useState<number>(initialGraphGlobalRightTimestamp);
  const [graphFocusedLeftTimestamp, setGraphFocusedLeftTimestamp] = React.useState<number>(initialGraphFocusedLeftTimestamp);
  const [graphFocusedRightTimestamp, setGraphFocusedRightTimestamp] = React.useState<number>(initialGraphFocusedRightTimestamp);
  const [evaluatingGraphQuery, setEvaluatingGraphQuery] = React.useState<GraphQuery | undefined>({
    clauses: urlState?.clauses ?? [],
    displayTimeZone,
    timestampFilter: {
      lowerBoundTimestamp: initialGraphGlobalLeftTimestamp,
      upperBoundTimestamp: initialGraphGlobalRightTimestamp,
    },
  });
  const evaluatingGraphQueryId: React.MutableRefObject<string> = React.useRef(crypto.randomUUID());

  const [sidePanelState, setSidePanelState] = React.useState<SidePanelState>({ isOpen: false });

  const queryManager: QueryManager = QueryManager.getInstance();
  queryManager.setOnQueryEvaluated(onQueryEvaluated);

  React.useEffect(
    function evaluateGridQuery(): void {
      if (evaluatingGridQuery == null) {
        return;
      }
      const queryId: string = crypto.randomUUID();
      evaluatingGridQueryId.current = queryId;

      queryManager.queueEvaluation(namespaceId, QueryUtils.toGridSqlQuery(namespaceId, evaluatingGridQuery), queryId);
    },
    [evaluatingGridQuery, namespaceId, queryManager],
  );

  React.useEffect(
    function evaluateGraphQuery(): void {
      if (evaluatingGraphQuery == null) {
        return;
      }
      const queryId: string = crypto.randomUUID();
      evaluatingGraphQueryId.current = queryId;

      queryManager.queueEvaluation(
        namespaceId,
        QueryUtils.toGraphSqlQuery(
          namespaceId,
          evaluatingGraphQuery,
          pinnedRows.map((row) => StrictUtils.ensureDefined(row[eventIdColumnName])),
        ),
        queryId,
      );
    },
    [evaluatingGraphQuery, namespaceId, pinnedRows, queryManager],
  );

  const columnDefinitions: ColDef<QueryResultRow>[] = [];
  if (gridQueryResult) {
    columnDefinitions.push({
      headerName: "",
      cellRenderer: PinnedColumnCellRenderer,
      cellRendererParams: {
        onChange: (checked: boolean, event: QueryResultRow): void => {
          if (checked) {
            setPinnedRows([...pinnedRows, event]);
          } else {
            setPinnedRows(pinnedRows.filter((e) => e[eventIdColumnName] !== event[eventIdColumnName]));
          }
        },
      },
      valueGetter: (params): boolean => (params.data ? pinnedRows.map((row) => row[eventIdColumnName]).includes(params.data[eventIdColumnName]) : false),
      width: 70,
    });

    columnDefinitions.push(
      ...gridQueryResult.meta
        .filter((column) => gridColumnNames.includes(column.name))
        .map(
          ({ type, name }): ColDef<QueryResultRow> => ({
            headerName: name,
            headerClass: "font-mono",
            cellDataType: getKnownCellDataType(type),
            cellRenderer: getKnownCellRenderer(name),
            valueFormatter: getKnownValueFormatter(name),
            sortable: false,
            width: 200,
          }),
        ),
    );
  }
  const nonPinnedGridRows: QueryResultRow[] = gridQueryResult?.data.filter((row) => !pinnedRows.map((row) => row[eventIdColumnName]).includes(row[eventIdColumnName])) ?? [];
  const allGridRows: QueryResultRow[] = [...pinnedRows, ...nonPinnedGridRows];

  return (
    <div className="flex w-full h-screen justify-center">
      <div className="flex flex-col items-center w-full h-full px-4 pt-6 space-y-8">
        <div className="flex flex-row w-full">
          <div className="w-full flex flex-col max-w-[90%] space-y-8">
            <RequestGraph
              displayTimeZone={displayTimeZone}
              focusedLeftTimestamp={graphFocusedLeftTimestamp}
              focusedRightTimestamp={graphFocusedRightTimestamp}
              globalLeftTimestamp={graphGlobalLeftTimestamp}
              globalRightTimestamp={graphGlobalRightTimestamp}
              countByTimestamp={graphCountByTimestamp}
              isLoading={evaluatingGraphQuery !== undefined}
              onFocusedTimeRangeChanged={onGraphFocusedTimeRangeChanged}
              onGlobalTimeRangeChanged={onGraphGlobalRangeChanged}
            />
            <div className="w-full flex flex-col space-y-2">
              <div className="w-full flex">
                <ClauseEditor queryResult={gridQueryResult ? { ...gridQueryResult, data: allGridRows } : undefined} onClauseCommitted={onClauseCommitted} ref={clauseEditorRef} />
                {/* TODO: "Learn more" linking to docs  */}
              </div>
              <div className="px-8">{renderClauses()}</div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 text-zinc-500 text-xs">
            <span className="self-center">Show times in</span>
            <div className="flex flex-row space-x-2 ">
              <span>UTC</span>
              <Toggle
                className="self-start"
                checked={displayTimeZone === DateDisplayTimeZone.CurrentTimeZone}
                onChange={(value) => setDisplayTimeZone(value ? DateDisplayTimeZone.CurrentTimeZone : DateDisplayTimeZone.UTC)}
              />
              <span>Local</span>
            </div>
          </div>
        </div>
        <div className="w-full h-full flex flex-col">
          <LazyGrid
            fallback={
              <div className="px-8 h-full flex justify-center items-center">
                <Spinner className="scale-[200%]" />
              </div>
            }
            className="w-full h-full"
            columnDefinitions={columnDefinitions}
            headerHeight={45}
            loading={evaluatingGridQuery !== undefined}
            onRowDoubleClicked={onRowDoubleClicked}
            pinnedTopRowData={pinnedRows}
            queryResult={gridQueryResult}
            rowClassRules={{
              "last-pinned-row": (params) => pinnedRows.length > 0 && params.rowIndex === pinnedRows.length - 1,
            }}
            rowHeight={35}
          />
        </div>
      </div>
      {sidePanelState.isOpen ? (
        <RequestDetailsSidePanel
          closeSidePanel={() => {
            setSidePanelState({ isOpen: false });
            EventLogger.logEvent(SubtraceEventKind.GridColumnNamesChanged, { grid_column_names: gridColumnNames.join(",") });
          }}
          gridColumnNames={gridColumnNames}
          rowData={sidePanelState.rowData}
          setGridColumnNames={setGridColumnNames}
        />
      ) : null}
    </div>
  );

  function getKnownCellDataType(type: string): string | undefined {
    switch (type) {
      case "DateTime64(9)":
        return "dateString";
      case "String":
      case "Nullable(String)":
      case "LowCardinality(String)":
      case "UUID":
        return "text";
      case "UInt64":
      case "Nullable(UInt64)":
        return "number";
    }
  }

  function getKnownCellRenderer(name: string) {
    switch (SubtraceEvent.knownFieldsFromJSON(name)) {
      case SubtraceEvent.KnownFields.http_is_outgoing:
        return (params: CustomCellRendererProps<QueryResultRow, string>) => (
          <span className="h-full flex flex-row items-center">{params.valueFormatted === "true" ? <MoveUpRightIcon size={20} /> : <MoveDownLeftIcon size={20} />}</span>
        );
    }
  }

  function getKnownValueFormatter(name: string): ValueFormatterFunc<QueryResultRow, string> | undefined {
    switch (SubtraceEvent.knownFieldsFromJSON(name)) {
      case SubtraceEvent.KnownFields.time:
        return (params) => {
          if (!params.data) {
            return "";
          }
          // This is safe since we know that the timestamp is never null.
          const timestampString: string = StrictUtils.ensureDefined(params.data[name]);
          return DateUtils.formatTimestamp(new Date(`${timestampString}${timestampString.endsWith("Z") ? "" : "Z"}`), displayTimeZone);
        };
      case SubtraceEvent.KnownFields.http_duration:
        return (params) => (params.data ? DurationUtils.formatForDisplay(Number(params.data[name])) : "");
      default:
        return (params) => params.data?.[name] ?? "";
    }
  }

  function onGraphGlobalRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphGlobalLeftTimestamp(startTimestamp);
    setGraphGlobalRightTimestamp(endTimestamp);
    setEvaluatingGraphQuery({
      clauses: (evaluatingGridQuery ?? appliedGridQuery)?.clauses ?? [],
      displayTimeZone,
      timestampFilter: {
        lowerBoundTimestamp: startTimestamp,
        upperBoundTimestamp: endTimestamp,
      },
    });
  }

  function onGraphFocusedTimeRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphFocusedLeftTimestamp(startTimestamp);
    setGraphFocusedRightTimestamp(endTimestamp);
    setEvaluatingGridQuery({
      clauses: (evaluatingGridQuery ?? appliedGridQuery)?.clauses ?? [],
      timestampFilter: {
        lowerBoundTimestamp: startTimestamp,
        upperBoundTimestamp: endTimestamp,
      },
    });
  }

  function onClauseCommitted(clause: Clause): void {
    const baseGridQuery: GridQuery | undefined = evaluatingGridQuery ?? appliedGridQuery;
    const clausesToDisplay: Clause[] = baseGridQuery?.clauses ?? [];
    const clauseIndex: number = clausesToDisplay.findIndex((_clause) => _clause.clauseId === clause.clauseId);
    if (clauseIndex !== -1) {
      // Editing an existing clause
      const newGridQuery: GridQuery = {
        clauses: [...clausesToDisplay.slice(0, clauseIndex), clause, ...clausesToDisplay.slice(clauseIndex + 1)],
        timestampFilter: baseGridQuery?.timestampFilter,
      };
      setEvaluatingGridQuery(newGridQuery);

      setEvaluatingGraphQuery({
        ...newGridQuery,
        displayTimeZone,
        timestampFilter: {
          lowerBoundTimestamp: graphFocusedLeftTimestamp,
          upperBoundTimestamp: graphFocusedRightTimestamp,
        },
      });
    } else {
      // Adding a new clause
      const newGridQuery: GridQuery = {
        clauses: [...clausesToDisplay, clause],
        timestampFilter: baseGridQuery?.timestampFilter,
      };

      setEvaluatingGridQuery(newGridQuery);
      setEvaluatingGraphQuery({
        ...newGridQuery,
        displayTimeZone,
        timestampFilter: {
          lowerBoundTimestamp: graphFocusedLeftTimestamp,
          upperBoundTimestamp: graphFocusedRightTimestamp,
        },
      });
    }
  }

  function onClauseClicked(clause: Clause): void {
    clauseEditorRef.current?.setClauseBeingEdited(clause);
  }

  function onQueryEvaluated(queryId: string, queryResult: QueryResult): void {
    if (queryId === evaluatingGridQueryId.current) {
      setGridQueryResult(queryResult);
      setAppliedGridQuery(evaluatingGridQuery);
      setEvaluatingGridQuery(undefined);
      updateUrl();
    } else if (queryId === evaluatingGraphQueryId.current) {
      const countsByBinnedTimestamp: Map<number, number> = new Map(
        queryResult.data.map((row) => {
          let dateString: string = StrictUtils.ensureDefined(row[GraphQueryColumnName.BinnedTimestamp]);
          // Ensure that we interpret this as a UTC timestamp.
          dateString = dateString.endsWith("Z") ? dateString : dateString + "Z";
          const count: number = parseInt(StrictUtils.ensureDefined(row[GraphQueryColumnName.Count]));
          return [new Date(dateString).valueOf(), count];
        }),
      );

      setGraphCountByTimestamp(countsByBinnedTimestamp);
      setEvaluatingGraphQuery(undefined);
      updateUrl();
    }
    return;
  }

  function renderClauses(): React.ReactNode {
    const baseGridQuery: GridQuery | undefined = evaluatingGridQuery ?? appliedGridQuery;
    const clausesToDisplay: Clause[] = baseGridQuery?.clauses ?? [];

    return (
      <div className="space-x-2 flex flex-row flex-wrap font-mono">
        {clausesToDisplay.map((clause) => (
          <div
            key={clause.clauseId}
            className="px-3 py-[6px] my-1 text-[11px] leading-[11px] font-medium text-zinc-400 bg-zinc-900/70 outline outline-[1px] outline-zinc-800/70 rounded space-x-3 flex flex-row hover:brightness-[1.15] cursor-pointer"
            onClick={() => onClauseClicked(clause)}
            title={clause.clauseText}
          >
            <span className="select-none">{clause.clauseText}</span>
            <span
              className="hover:text-zinc-300"
              onClick={(event) => {
                event.stopPropagation();
                const newGridQuery: GridQuery = {
                  ...baseGridQuery,
                  clauses: clausesToDisplay.filter((_clause) => _clause.clauseId !== clause.clauseId),
                  timestampFilter: baseGridQuery?.timestampFilter,
                };
                setEvaluatingGridQuery(newGridQuery);
                setEvaluatingGraphQuery({
                  ...newGridQuery,
                  displayTimeZone,
                  timestampFilter: {
                    lowerBoundTimestamp: graphFocusedLeftTimestamp,
                    upperBoundTimestamp: graphFocusedRightTimestamp,
                  },
                });
              }}
            >
              <DeleteIcon className="-my-[1px] h-[13px] w-[13px]" />
            </span>
          </div>
        ))}
      </div>
    );
  }

  function onRowDoubleClicked(event: RowDoubleClickedEvent<QueryResultRow>): void {
    const nativeEvent: MouseEvent | null | undefined = event.event as MouseEvent | null | undefined;
    if (!(nativeEvent?.ctrlKey || nativeEvent?.metaKey) && event.data) {
      setSidePanelState({ isOpen: true, rowData: event.data });
    }
  }

  function updateUrl(): void {
    const urlState: UrlState = {
      clauses: appliedGridQuery?.clauses,
      focusedTimestampFilter: { lowerBoundTimestamp: graphFocusedLeftTimestamp, upperBoundTimestamp: graphFocusedRightTimestamp },
      globalTimestampFilter: { lowerBoundTimestamp: graphGlobalLeftTimestamp, upperBoundTimestamp: graphGlobalRightTimestamp },
    };

    setSearchParams(
      (params) => ({
        ...params,
        [filterSearchParamName]: btoa(JsonUtils.stringify(urlState)),
      }),
      { replace: true },
    );
  }
}

type SidePanelState =
  | {
      isOpen: true;
      rowData: QueryResultRow;
    }
  | {
      isOpen: false;
    };
