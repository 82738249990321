import * as ApiEndpointUtils from "ApiEndpointUtils";

const SUBTRACE_TAGS_HEADER_NAME: string = "x-subtrace-tags";

export async function get(
  path: string,
  options: {
    signal?: AbortSignal;
    subtraceTags?: Partial<Record<string, string | undefined>>;
  },
): Promise<Response> {
  return performApiRequest(path, "GET", /* body */ undefined, options);
}

export async function post<TBody>(
  path: string,
  body: TBody,
  options: {
    signal?: AbortSignal;
    subtraceTags?: Partial<Record<string, string | undefined>>;
  },
): Promise<Response> {
  return performApiRequest(path, "POST", body, options);
}

async function performApiRequest<TBody>(
  path: string,
  method: "GET" | "POST",
  body: TBody,
  options: {
    signal?: AbortSignal;
    subtraceTags?: Partial<Record<string, string | undefined>>;
  },
): Promise<Response> {
  const { subtraceTags, signal } = options;

  return fetch(`${ApiEndpointUtils.getApiEndpoint()}${path}`, {
    credentials: "include",
    headers: subtraceTags
      ? {
          [SUBTRACE_TAGS_HEADER_NAME]: Object.entries(subtraceTags)
            .map(([key, value]) => `${key}="${value == null ? "" : value}"`)
            .join(" "),
        }
      : undefined,
    method,
    signal,
    body: JSON.stringify(body),
  });
}

export async function assertStatus(response: Response, ...expected: number[]): Promise<void> {
  if (expected.includes(response.status)) {
    return;
  }

  let message = `got unexpected status code ${response.status}`;
  if (response.headers.has("x-subtrace-id")) {
    message = `${message} (x-subtrace-id: ${response.headers.get("x-subtrace-id")})`;
  }

  try {
    const data: { error?: string } = await response.json();
    if (data.error) {
      message = `${message}: ${data.error}`;
    }
  } catch {
    // Parsing the response body as a JSON isn't always possible.
  }

  throw new Error(message);
}
