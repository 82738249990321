import * as React from "react";
import { Loader2, Trash } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { DialogManager } from "DialogManager";
import { GetApiTokenDialog } from "GetApiTokenDialog";
import { InsufficientPermissionsDialog } from "InsufficientPermissionsDialog";
import { DeleteConfirmationDialog } from "DeleteConfirmationDialog";

function List(props: {
  namespaceId: string;
  tokens: Api.ListTokens_Item[] | null;
  generateToken: (namespaceId: string) => Promise<void>;
  refreshTokens: (namespaceId: string, signal?: AbortSignal) => Promise<void>;
}): React.ReactNode {
  const dialogManager: DialogManager = CustomHooks.useDialogManager();
  const { namespaceId } = props;

  if (props.tokens === null) {
    return (
      <div className="pt-4 w-full flex items-center">
        <div className="w-full h-full flex justify-center items-center rounded-md text-zinc-600">
          <div className="w-4 h-4 flex justify-center items-center">
            <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
              <Loader2 />
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (props.tokens.length === 0) {
    return (
      <div className="pt-4 w-full flex items-center">
        <div className="w-full h-full flex justify-center items-center border border-zinc-800/80 rounded-md text-zinc-600 py-8">
          <div className="flex justify-center items-center">
            <div className="text-xs flex flex-col space-y-2">
              <span>This namespace has no tokens.</span>
              <button
                className="mx-auto rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1"
                onClick={() => props.generateToken(namespaceId)}
              >
                <span>New token</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="rounded overflow-hidden border border-zinc-800/80 w-full flex flex-col divide-y divide-zinc-800/80">
      {props.tokens.map(({ tokenId, createTime, name, creatorEmail }) => {
        const diff = (Date.now() - new Date(createTime).getTime()) / 1000;

        let count: number, unit: string;
        if (diff < 60) [count, unit] = [Math.round(diff), "second"];
        else if (diff < 60 * 60) [count, unit] = [Math.round(diff / 60), "minute"];
        else if (diff < 24 * 60 * 60) [count, unit] = [Math.round(diff / 60 / 60), "hour"];
        else [count, unit] = [Math.round(diff / 60 / 60 / 24), "day"];
        if (count !== 1) unit += "s";

        return (
          <div className="w-full px-4 py-2 flex justify-between items-center hover:bg-zinc-900 group relative" key={tokenId}>
            <div className="font-mono text-zinc-300 text-sm truncated text-ellipsis">{name}</div>
            <div className="flex flex-col space-y-[1px] items-end group-hover:opacity-0">
              <div className="font-medium text-zinc-600 text-[10px]">{creatorEmail}</div>
              <div title={createTime} className="text-zinc-600/80 text-[10px]">
                {count} {unit} ago
              </div>
            </div>
            <button
              onClick={() => deleteToken(tokenId, namespaceId)}
              title="Delete"
              className="hidden absolute top-0 right-0 w-16 cursor-pointer group-hover:flex justify-center items-center h-full bg-gradient-to-l from-red-900/20 hover:from-red-900/30 to-transparent text-red-600 hover:text-red-400"
            >
              <span className="w-[14px] h-[14px] flex items-center">
                <Trash />
              </span>
            </button>
          </div>
        );
      })}
    </div>
  );

  async function deleteToken(tokenId: string, namespaceId: string): Promise<void> {
    const wasCommitted: boolean = await dialogManager.show(
      <DeleteConfirmationDialog
        message="Are you sure you want to delete this token? This action cannot be undone."
        closeModal={(wasCommitted: boolean) => dialogManager.hide(wasCommitted)}
      />,
    );

    if (!wasCommitted) {
      return;
    }

    const response: Response = await ApiUtils.post("/api/DeleteToken", { tokenId }, { subtraceTags: { namespace_id: namespaceId } });
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to delete a token in this namespace." closeModal={() => dialogManager.hide(undefined)} />,
      );
    }

    await ApiUtils.assertStatus(response, 200);
    await props.refreshTokens(namespaceId);
  }
}

export function TokensPage(): React.ReactNode {
  const [tokens, setTokens] = React.useState<Api.ListTokens_Item[] | null>(null);
  const dialogManager: DialogManager = CustomHooks.useDialogManager();
  const [namespace] = CustomHooks.useNamespaceState();
  const { namespaceId } = namespace;

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    refreshTokens(namespaceId, controller.signal);

    return (): void => {
      controller.abort("Cleaning up effect refreshTokens");
    };
  }, [namespaceId]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-xl px-8 py-12">
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between space-x-2">
              <div className="text-xs text-zinc-500 max-w-80">
                Subtrace API tokens are used by clients to authenticate when pushing or querying tracing data.{" "}
                <a className="brightness-[1.3] hover:brightness-[1.5]" href="https://docs.subtrace.dev">
                  Learn more.
                </a>
              </div>
              <button
                className="rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1"
                onClick={() => generateToken(namespaceId)}
              >
                <span>Create API token</span>
                <span>&rarr;</span>
              </button>
            </div>
          </div>
          <List namespaceId={namespaceId} tokens={tokens} refreshTokens={refreshTokens} generateToken={generateToken} />
        </div>
      </div>
    </div>
  );

  async function generateToken(namespaceId: string): Promise<void> {
    const request: Api.GenerateToken_Request = { namespaceId };
    const response: Response = await ApiUtils.post("/api/GenerateToken", request, { subtraceTags: { namespace_id: namespaceId } });
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to generate a token in this namespace." closeModal={() => dialogManager.hide(undefined)} />,
      );
    }
    await ApiUtils.assertStatus(response, 200);

    const { token }: Api.GenerateToken_Response = await response.json();
    await dialogManager.show(<GetApiTokenDialog apiToken={token} closeModal={() => dialogManager.hide(undefined)} />);
    await refreshTokens(namespaceId);
  }

  async function refreshTokens(namespaceId: string, signal?: AbortSignal): Promise<void> {
    const request: Api.ListTokens_Request = { namespaceId };
    try {
      const response: Response = await ApiUtils.post("/api/ListTokens", request, { signal, subtraceTags: { namespace_id: namespaceId } });
      await ApiUtils.assertStatus(response, 200);

      const { tokens }: Api.ListTokens_Response = await response.json();
      setTokens(tokens);
    } catch {
      if (signal?.aborted) {
        // Do nothing, the API call was canceled
      }
    }
  }
}
