import React from "react";
import { ClipboardCopyIcon, EyeIcon, EyeOffIcon, XIcon } from "lucide-react";

import * as StyleUtils from "Utils/StyleUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import { QueryResultRow } from "QueryResult";

export function RequestDetailsSidePanel(props: RequestDetailsSidePanelProps): React.ReactElement | null {
  const [copyButtonTooltipText, setCopyButtonTooltipText] = React.useState<string>("Copy");

  return (
    <div className="absolute w-screen h-screen inset-0 overflow-x-hidden">
      <div className="absolute w-[25%] right-0 top-0 h-screen animate-slide-in-right z-10 rounded-lg bg-zinc-900 border-[1px] border-zinc-700/80 outline outline-[1px] outline-black">
        <XIcon
          className={StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white")}
          onClick={() => props.closeSidePanel()}
        />
        <div className="flex flex-col space-y-6 p-4 text-white h-full">
          <div className="font-medium text-lg">Request details</div>
          <div className="flex flex-col space-y-4 overflow-y-auto">
            {[
              KnownSection.TopLevel,
              KnownSection.HttpRequest,
              KnownSection.HttpResponse,
              KnownSection.Process,
              KnownSection.GCP,
              KnownSection.Kuberenetes,
              KnownSection.Custom,
            ].map((knownSection) => renderSection(knownSection))}
          </div>
        </div>
      </div>
    </div>
  );

  function changeColumnVisibility(columnName: string, isVisible: boolean): void {
    if (isVisible) {
      props.setGridColumnNames([...props.gridColumnNames, columnName]);
    } else {
      props.setGridColumnNames(props.gridColumnNames.filter((_columnName) => _columnName !== columnName));
    }
  }

  function getSectionHeaderName(knownSection: KnownSection): string {
    switch (knownSection) {
      case KnownSection.TopLevel:
        return "";
      case KnownSection.HttpRequest:
        return "HTTP request details";
      case KnownSection.HttpResponse:
        return "HTTP response details";
      case KnownSection.Process:
        return "Process info";
      case KnownSection.GCP:
        return "GCP info";
      case KnownSection.Kuberenetes:
        return "Kubernetes info";
      case KnownSection.Custom:
        return "Custom";
    }
  }

  function renderSection(knownSection: KnownSection): React.ReactNode {
    // This cast is fine because we check for `value = null`
    const sectionFieldsAndValues: [string, string][] = Object.entries(props.rowData).filter(
      ([name, value]) => sectionByFieldNames[SubtraceEvent.knownFieldsFromJSON(name)] === knownSection && value != null && value.length > 0,
    ) as [string, string][];

    if (sectionFieldsAndValues.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col space-y-2" key={knownSection}>
        <span className="text-sm">{getSectionHeaderName(knownSection)}</span>
        <div className="flex flex-col space-y-1">{sectionFieldsAndValues.map(([field, value]) => renderField(field, value))}</div>
      </div>
    );
  }

  function renderField(field: string, value: string | number): React.ReactElement {
    const valueString: string = value.toString();
    const isColumnVisible: boolean = props.gridColumnNames.includes(field);
    const displayedText: string = `${field}: ${valueString}`;

    return (
      <div key={field} className="group/item flex flex-row content-center text-xs" onMouseLeave={() => setCopyButtonTooltipText("Copy")}>
        <div className="flex flex-row space-x-2 grow overflow-x-hidden">
          <button
            className={StyleUtils.mergeClassNames(isColumnVisible ? "text-zinc-300 hover:text-zinc-100" : "text-zinc-500 hover:text-zinc-400")}
            onClick={() => changeColumnVisibility(field, !isColumnVisible)}
          >
            {isColumnVisible ? <EyeIcon size={15} /> : <EyeOffIcon size={15} />}
          </button>
          <span className="font-mono whitespace-nowrap text-ellipsis overflow-x-hidden">
            <span title={displayedText}>{displayedText}</span>
          </span>
        </div>
        <span className="ml-2 mr-6 group/copy-button relative invisible group-hover/item:visible">
          <ClipboardCopyIcon
            size={15}
            className="text-zinc-300 hover:text-zinc-100 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(valueString);
              setCopyButtonTooltipText("Copied");
            }}
          />
          <span className="invisible group-hover/copy-button:visible absolute text-xs border rounded-sm border-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-[150%] p-1 bg-zinc-700 text-nowrap">
            {copyButtonTooltipText}
          </span>
        </span>
      </div>
    );
  }
}

export interface RequestDetailsSidePanelProps {
  rowData: QueryResultRow;
  gridColumnNames: string[];

  closeSidePanel: () => void;
  setGridColumnNames: (columnNames: string[]) => void;
}

const enum KnownSection {
  TopLevel = "TopLevel",
  HttpRequest = "HttpRequest",
  HttpResponse = "HttpResponse",
  Process = "Process",
  GCP = "GCP",
  Kuberenetes = "Kubernetes",
  Custom = "Custom",
}

const sectionByFieldNames: { [knownField in SubtraceEvent.KnownFields]: KnownSection } = {
  [SubtraceEvent.KnownFields.time]: KnownSection.TopLevel,
  [SubtraceEvent.KnownFields.event_id]: KnownSection.TopLevel,
  [SubtraceEvent.KnownFields.service]: KnownSection.TopLevel,
  [SubtraceEvent.KnownFields.hostname]: KnownSection.TopLevel,
  [SubtraceEvent.KnownFields.protocol]: KnownSection.TopLevel,
  [SubtraceEvent.KnownFields.gcp_project]: KnownSection.GCP,
  [SubtraceEvent.KnownFields.kubernetes_namespace]: KnownSection.Kuberenetes,
  [SubtraceEvent.KnownFields.gke_cluster_location]: KnownSection.Kuberenetes,
  [SubtraceEvent.KnownFields.gke_cluster_name]: KnownSection.Kuberenetes,
  [SubtraceEvent.KnownFields.gke_node_name]: KnownSection.Kuberenetes,
  [SubtraceEvent.KnownFields.process_id]: KnownSection.Process,
  [SubtraceEvent.KnownFields.process_executable_name]: KnownSection.Process,
  [SubtraceEvent.KnownFields.process_executable_size]: KnownSection.Process,
  [SubtraceEvent.KnownFields.process_command_line]: KnownSection.Process,
  [SubtraceEvent.KnownFields.process_user]: KnownSection.Process,
  [SubtraceEvent.KnownFields.http_version]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_is_outgoing]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.tls_server_name]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_client_addr]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_server_addr]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_duration]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_method]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_path]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_body_size_bytes_wire]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_transfer_encoding]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_content_type]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_content_encoding]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_content_length]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_host]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_req_x_forwarded_for]: KnownSection.HttpRequest,
  [SubtraceEvent.KnownFields.http_resp_status_code]: KnownSection.HttpResponse,
  [SubtraceEvent.KnownFields.http_resp_body_size_bytes_wire]: KnownSection.HttpResponse,
  [SubtraceEvent.KnownFields.http_resp_transfer_encoding]: KnownSection.HttpResponse,
  [SubtraceEvent.KnownFields.http_resp_content_type]: KnownSection.HttpResponse,
  [SubtraceEvent.KnownFields.http_resp_content_encoding]: KnownSection.HttpResponse,
  [SubtraceEvent.KnownFields.http_resp_content_length]: KnownSection.HttpResponse,
  [SubtraceEvent.KnownFields.UNRECOGNIZED]: KnownSection.Custom,
};
