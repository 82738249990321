import * as React from "react";
import { GridProps } from "./Grid";

const GridComponent: React.LazyExoticComponent<React.ComponentType<GridProps>> = React.lazy(() => import("./Grid").then(({ Grid }) => ({ default: Grid })));

export function LazyGrid(props: LazyGridProps): React.ReactElement {
  return (
    <React.Suspense fallback={props.fallback}>
      <GridComponent {...props} />
    </React.Suspense>
  );
}

export interface LazyGridProps extends GridProps {
  fallback?: React.ReactNode;
}
