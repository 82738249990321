import * as React from "react";
import { type CustomCellRendererProps } from "ag-grid-react";
import { PinIcon, PinOffIcon } from "lucide-react";

export function PinnedColumnCellRenderer(props: CustomCellRendererProps<string, boolean | undefined>): React.ReactElement {
  return (
    <div className="h-full w-full flex flex-row items-center justify-center">
      {props.value ? (
        <PinOffIcon className="cursor-pointer stroke-zinc-400 hover:stroke-white" onClick={() => onPinnedChange(/* pinned */ false)} size={15} />
      ) : (
        <PinIcon className="cursor-pointer stroke-zinc-400 hover:stroke-white" onClick={() => onPinnedChange(/* pinned */ true)} size={15} />
      )}
    </div>
  );

  function onPinnedChange(pinned: boolean): void {
    // This cast is necessary since there is no way to know that we're adding the onChange property
    // as a part of the cellRendererParams when defining this column in the grid.
    (props as any).onChange(pinned, props.data);
  }
}
