import * as React from "react";
import * as StyleUtils from "Utils/StyleUtils";

export function TextField(props: TextFieldProps): React.ReactElement {
  return (
    <div className={StyleUtils.mergeClassNames("flex flex-col", props.classNames?.root)}>
      {props.label ? <span className="text-white text-sm">{props.label}</span> : null}
      <input
        autoFocus={props.autoFocus}
        className={StyleUtils.mergeClassNames(
          "rounded-md bg-slate-200 cursor-pointer hover:brightness-[1.05] px-4 py-2 text-sm outline outline-2 outline-zinc-600/60 active:outline-zinc-600/80 focus:outline-zinc-600/70",
          props.classNames?.input,
        )}
        disabled={props.disabled}
        onChange={onValueChange}
        onKeyDown={onKeyDown}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
      />
      {props.errorMessage ? <span className="text-red-600 text-xs">{props.errorMessage}</span> : null}
    </div>
  );

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    props.onKeyDown?.(event);
  }

  function onValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
    props.onChange(event.target.value);
  }
}

export interface TextFieldProps {
  autoFocus?: boolean;
  classNames?: {
    root?: string;
    input?: string;
  };
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  value: string;

  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
