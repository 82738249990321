import * as React from "react";
import * as ReactDomClient from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import * as Api from "ApiContracts/control/api/api";
import * as CustomHooks from "CustomHooks";
import * as NamespaceUtils from "NamespaceUtils";
import { AppErrorBoundary } from "AppErrorBoundary";
import { Deferred } from "Deferred";
import { DialogContext } from "DialogContext";
import { DialogManager } from "DialogManager";
import { LandingPage } from "LandingPage";
import { LoginPage } from "./LoginPage";
import { NamespaceContext } from "NamespaceContext";
import { NamespacesPage } from "NamespacesPage";
import { Navigation } from "Navigation";
import { Overlay } from "Overlay";
import { RequestsPage } from "RequestsPage";
import { TokensPage } from "TokensPage";
import { User } from "User";
import { UsersPage } from "UsersPage";

import "./style.css";

const rootElement: Element = document.getElementById("root")!;
ReactDomClient.createRoot(rootElement).render(<App />);

function App(): React.ReactNode {
  const [dialogState, setDialogState] = React.useState<DialogState>({ isDialogOpen: false });
  let deferred: Deferred<any>;

  const dialogManager: DialogManager = {
    show: (dialogComponent: React.ReactNode) => {
      deferred = new Deferred();

      setDialogState({
        isDialogOpen: true,
        dialogComponent,
      });

      return deferred.promise;
    },
    hide: (result: any) => {
      setDialogState({ isDialogOpen: false });
      deferred.resolve(result);
    },
  };

  const currentUser: User | undefined = CustomHooks.useCurrentUser();
  const [namespace, setNamespace] = React.useState<Api.ListNamespaces_Item | undefined>(undefined);

  React.useEffect(() => {
    if (currentUser?.isLoggedIn) {
      NamespaceUtils.getDefaultNamespace().then((_namespace) => setNamespace(_namespace));
    }
  }, [currentUser]);

  return (
    <React.StrictMode>
      <AppErrorBoundary>
        <DialogContext.Provider value={dialogManager}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/dashboard" element={<Navigate to="/dashboard/requests" />} />
              <Route path="/dashboard/*" element={renderDashboardRoutes()} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
            {dialogState.isDialogOpen ? <Overlay className="absolute inset-0 w-dvw h-dvh flex items-center justify-center">{dialogState.dialogComponent}</Overlay> : null}
          </BrowserRouter>
        </DialogContext.Provider>
      </AppErrorBoundary>
    </React.StrictMode>
  );

  function renderDashboardRoutes(): React.ReactNode {
    if (currentUser === undefined) {
      return null;
    }

    if (!currentUser.isLoggedIn) {
      return <Navigate to={`/login?next=${encodeURIComponent(window.location.pathname)}`} />;
    }

    if (namespace === undefined) {
      return null;
    }

    return (
      <NamespaceContext.Provider value={[namespace, setNamespace]}>
        <div className="w-screen h-screen max-w-[100vw] max-h-screen flex">
          <Navigation />
          <div className="basis-2/3 grow shrink flex overflow-auto">
            <Routes>
              <Route path="namespaces" element={<NamespacesPage />} />
              <Route path="requests" element={<RequestsPage />} />
              <Route path="tokens" element={<TokensPage />} />
              <Route path="users" element={<UsersPage />} />\
            </Routes>
          </div>
        </div>
      </NamespaceContext.Provider>
    );
  }
}

type DialogState =
  | {
      isDialogOpen: false;
    }
  | {
      isDialogOpen: true;
      dialogComponent: React.ReactNode;
    };
